<template>
  <div class="full_popup" v-if="details.debitOrderID">
    <div class="row h-100 m-0 align-items-center justify-content-center">
      <div class="col-11 col-md-8 col-lg-6 col-xxl-6 p-0 card_item">
        <div class="row mx-0 h-100 justify-content-center">
          <div class="col-12 p-3 pb-0">
            <div class="row m-0 justify-content-center holder">
              <div class="bg_holder">
                <div class="background" >
                </div>
              </div>
              <div class="col-12 p-3 above">
                <div class="row mb-5 d-md-none">
                  <div class="col-12 text-center">
                    <Button color="dark" size="small" class="white-text" btnText="Back to Debit Orders" icon2="arrow" @buttonClicked="$emit('close')">
                      <template v-slot:iconStart>
                        <IconArrowBack size="size16" />
                      </template>
                    </Button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-10 offset-md-1 pt-2">
                    <div class="row justify-content-center">
                      <div class="col-auto text-center p-0 card_title">
                        EDIT DEBIT ORDER: {{item.debitOrderID}}
                        <div class="row m-0 justify-content-center" >
                          <div class="col-12 text-center pt-3 border_bottom">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-1 text-end cursor d-none d-md-block" @click="$emit('close')">
                    <ios-close-icon class="inline-icon green-text-dark" />
                  </div>
                </div>
                <div class="row m-0 justify-content-center">
                  <div class="col-12">
                    <div class="row m-0 justify-content-center" >
                      <div class="col-md-11 px-0 my-3 card_item_inner">
                        <div class="row mx-0 h-100 justify-content-center">
                          <div class="col-12 p-0">
                            <div class="row m-0 justify-content-center">
                              <div class="col-12 p-0 border_top" v-for="(donationItem, index) in details.donations" :key="index">
                                <div class="row m-0 align-items-center borders py-2">
                                  <div class="col-12 px-4">
                                    <DebitOrderSelectProject :item="donationItem" />
                                    <div class="row mx-0 my-3 align-items-center">
                                      <div class="col-9 p-0">
                                        <div class="row">
                                          <div class="col-12 col-md-auto mb-1">
                                            <CurrencyField :label="donationItem.donationType.description" v-model="donationItem.amount" size="small" color="outline-green" />
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-3 bold text-end d-none d-md-block">
                                        {{ donationItem.currency.symbol }} {{ parseFloat(Math.round(donationItem.amount * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}}
                                      </div>
                                    </div>
                                    <div class="row my-md-3 mt-3 mb-2">
                                      <div class="col-12 bold text-end d-block d-md-none mt-2">
                                        {{ donationItem.currency.symbol }} {{ parseFloat(Math.round(donationItem.amount * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row m-0 justify-content-center" >
                      <div class="col-md-11 px-0 my-3">
                        <div class="row align-items-center">
                          <div class="col-md-4 text-center green-text mb-3 mb-md-0">
                            <TextField type="text" color="gold" placeholder="Total" v-model="displayTotal" disabled />
                            <!-- <Button color="green" width="100" :btnText="displayTotal" /> -->
                            <div class="medium green-text font10 letter_spacing mt-2">
                              DONATION TOTAL
                            </div>
                          </div>
                          <div class="col-md-4 text-center mb-3 mb-md-0">
                            <div class="row m-0 selectFrequency">
                              <div class="col-12 form-control holder">
                                <SelectAll
                                  v-model="debitDay"
                                  id="debitDay"
                                  :options="debitDayOptions"
                                  placeholder="Debit Order Day"
                                  displayValue="name"
                                  color="gold"
                                  :autoSelect="true"
                                />
                              </div>
                            </div>
                            <div class="medium green-text font10 letter_spacing mt-2">
                              DAY
                            </div>
                          </div>
                          <div class="col-md-4 text-center green-text mb-3 mb-md-0">
                            <TextField type="text" color="gold" placeholder="Start Date" v-model="date" disabled />
                            <div class="medium green-text font10 letter_spacing mt-2">
                              NEXT PAYMENT
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--BUTTONS-->
          <div class="col-12 px-4 align-self-end background-green">
            <div class="row py-3 align-items-center justify-content-center">
              <div class="col-8 col-md-5 px-2 mb-2 mb-md-0" >
                <Button color="red" size="" width="100" btnText="Cancel Changes" icon="arrow" @buttonClicked="cancelEdit">
                  <ios-close-circle-outline-icon class="inline-icon" />
                </Button>
              </div>
              <div class="col-8 col-md-5 px-2" >
                <Button color="green_light" size="" width="100" btnText="Save Changes" icon="arrow" @buttonClicked="save" :isLoading="isLoading">
                  <IconEdit size="size16"/>
                </Button>
              </div>
            </div>
          </div>
          <!--END BUTTONS-->
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

import { DateTime } from 'luxon'

export default {
  components: {
    Button: defineAsyncComponent(() => import('../components/Button.vue')),
    CurrencyField: defineAsyncComponent(() => import('../components/CurrencyField.vue')),
    TextField: defineAsyncComponent(() => import('../components/TextField.vue')),
    IconEdit: defineAsyncComponent(() => import('../components/icons/IconEdit.vue')),
    IconArrowBack: defineAsyncComponent(() => import('../components/icons/IconArrowBack.vue')),
    DebitOrderSelectProject: defineAsyncComponent(() => import('./DebitOrderSelectProject.vue')),
    SelectAll: defineAsyncComponent(() => import('../components/SelectAll.vue')),
    'ios-close-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-close.vue')),
    'ios-close-circle-outline-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-close-circle-outline.vue'))
  },
  name: 'DebitOrderViewEditPopup',
  props: ['item'],
  data () {
    return {
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL,
      details: {},
      jsDate: null,
      debitDay: {},
      debitDayOptions: [
        {
          name: '1'
        },
        {
          name: '3'
        },
        {
          name: '15'
        },
        {
          name: '25'
        }
      ],
      frequencyOptions: [
        {
          val: 'DAILY',
          display: 'Daily'
        },
        {
          val: 'WEEKLY',
          display: 'Weekly'
        },
        {
          val: 'MONTHLY',
          display: 'Monthly'
        }
      ],
      isLoading: false
    }
  },
  async mounted () {
    this.details = { ...this.item }
    this.debitDay = { name: this.details.debitOrderDay.toString() }
  },
  watch: {
    details: {
      deep: true,
      handler () {
        this.setTotal()
      }
    },
    debitDay: {
      deep: true,
      handler () {
        this.setDay()
      }
    }
  },
  methods: {
    ...mapActions(['updateDebitOrder']),
    async save () {
      this.isLoading = true
      await this.updateDebitOrder(this.details)
      this.isLoading = false
      await this.cancelEdit()
    },
    cancelEdit () {
      this.$emit('close')
    },
    // setDate () {
    //   const nextPayment = this.originalDate
    //   const nextPaymentYear = DateTime.fromFormat(nextPayment, 'yyyy-MM-dd').year
    //   const nextPaymentMonth = DateTime.fromFormat(nextPayment, 'yyyy-MM-dd').month
    //   const adjustedPayment = DateTime.fromObject({ year: nextPaymentYear, month: nextPaymentMonth, day: this.details.debitOrderDay }).toMillis() // => '1982-05-25'
    //   const dateNow = DateTime.now().ts
    //   if (dateNow > adjustedPayment) {
    //     DateTime.fromMillis(adjustedPayment).plus({ month: 1 })
    //     this.details.startDate = DateTime.fromMillis(adjustedPayment).plus({ month: 1 }).ts
    //   } else {
    //     this.details.startDate = DateTime.fromMillis(adjustedPayment).ts
    //   }
    // },
    setDay () {
      this.details.debitOrderDay = parseInt(this.debitDay.name)
    },
    setTotal () {
      if (this.details.donations.length > 0) {
        let ret = 0
        this.details.donations.forEach(item => {
          ret += Number(item.amount)
          item.estimatedRandValue = item.amount
        })
        this.details.amount = ret
      } else {
        this.details.amount = 0
      }
    }
  },
  computed: {
    ...mapGetters(['user']),
    displayTotal () {
      return this.defaultCurrency + ' ' + parseFloat(Math.round(this.details.amount * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    originalDate () {
      const startDate = this.item.startDate
      const now = DateTime.now().ts
      const frequency = this.item.frequency
      let nextPaymentDate = null
      if (now > startDate) {
        if (frequency === 'DAILY') {
          nextPaymentDate = DateTime.now().startOf('day').plus({ days: 1 })
        } else if (frequency === 'WEEKLY') {
          const diff = Math.ceil(Math.abs(DateTime.fromMillis(startDate).diffNow('weeks').weeks))
          nextPaymentDate = DateTime.fromMillis(startDate).plus({ weeks: diff })
        } else if (frequency === 'MONTHLY') {
          const diff = Math.ceil(Math.abs(DateTime.fromMillis(startDate).diffNow('months').months))
          nextPaymentDate = DateTime.fromMillis(startDate).plus({ months: diff })
        }
      } else {
        nextPaymentDate = DateTime.fromMillis(startDate)
      }
      return DateTime.fromMillis(nextPaymentDate.ts).toFormat('yyyy-MM-dd')
    },
    date () {
      const debitOrderDay = this.details.debitOrderDay
      const startMonth = DateTime.fromMillis(this.item.startDate).month
      const startYear = DateTime.fromMillis(this.item.startDate).year
      const startDate = DateTime.fromObject({ year: startYear, month: startMonth, day: debitOrderDay }).toMillis()
      // const startDate = this.details.startDate
      const now = DateTime.now().ts
      const frequency = this.item.frequency
      let nextPaymentDate = null
      if (now > startDate) {
        if (frequency === 'DAILY') {
          nextPaymentDate = DateTime.now().startOf('day').plus({ days: 1 })
        } else if (frequency === 'WEEKLY') {
          const diff = Math.ceil(Math.abs(DateTime.fromMillis(startDate).diffNow('weeks').weeks))
          nextPaymentDate = DateTime.fromMillis(startDate).plus({ weeks: diff })
        } else if (frequency === 'MONTHLY') {
          const diff = Math.ceil(Math.abs(DateTime.fromMillis(startDate).diffNow('months').months))
          nextPaymentDate = DateTime.fromMillis(startDate).plus({ months: diff })
        }
      } else {
        nextPaymentDate = DateTime.fromMillis(startDate)
      }
      return DateTime.fromMillis(nextPaymentDate.ts).toFormat('yyyy-MM-dd')
    }
  }
}
</script>
<style scoped>
.full_popup {
  position: fixed;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  left: 0;
  top: 0;
}
.card_item {
  background-color: #F4F4F4F7;
  border-radius: 22px;
  box-shadow: 2px 8px 23px 3px rgb(0 0 0 / 20%);
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.card_item_inner {
  background-color: rgb(249, 250, 251);
  border-radius: 15px;
  /* box-shadow: 2px 8px 23px 3px rgb(0 0 0 / 20%); */
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.holder {
  position: relative;
  z-index: 2;
}
.above {
  z-index: inherit;
}
.bg_holder {
  position: absolute;
  left: -1.5rem;
  top: -1.5rem;
  z-index: 1;
  background-image: url(../assets/images/bg.png);
  background-size: 32% auto;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  max-width: calc(100% + 3rem);
  width: calc(100% + 3rem);
  height: calc(100% + 1.5rem);
  padding: 0;
}
.background {
  background-image: linear-gradient(180deg, #fffffff7 0%, #ffffff 100%);
  position: absolute;
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  background-position: top center;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.card_title {
  font-family: "quicksand_medium", Sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  color: var(--green-color-dark);
  letter-spacing: 4.3px;
}
.border_bottom {
  border-bottom: 1px solid var(--gold-color);
}
.profile_img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  position: relative;
  border: 2px solid #fff;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 5px 5px 35px 0px rgb(0 0 0 / 17%);
}
.profile_img img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.item_img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
  background-color: rgba(0, 0, 0, 0.1);
}
.item {
  color: #4E5356;
  font-size: 15px;
}
.delete {
  color: #E0E0E0;
  cursor: pointer;
}
.delete:hover {
  color: var(--red-color);
}
.borders {
  border-bottom: 1px solid #E0E0E0;
}
.borders:hover {
  background-color: rgb(244, 244, 244);
}
.hide {
  visibility: hidden;
}
.background-grey {
  background-color: rgb(244, 244, 244);
}
.item_nav {
  background-color: rgba(0, 0, 0, 0.53);
}
.hover:hover {
  color: var(--gold-color);
}
.disabled {
  opacity: 0.7;
}
.card_holder {
  border-radius: 15px;
}
.title {
  color: #3A3A3A;
  font-family: "quicksand_bold", Sans-serif;
  font-size: 17px;
  font-weight: 600;
}
.bg {
  background-color: #F4F4F4;
}
.bg:hover {
  background-color: #ffffff;
}
.bg_green {
  background-color: var(--green-color-dark);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.above_z {
  position: relative;
  z-index: 2;
}

.full_popup {
  position: fixed;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  left: 0;
  top: 0;
}
.card_item {
  background-color: #F4F4F4F7;
  border-radius: 22px;
  box-shadow: 2px 8px 23px 3px rgb(0 0 0 / 20%);
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.card_item_inner {
  background-color: rgb(249, 250, 251);
  border-radius: 15px;
  /* box-shadow: 2px 8px 23px 3px rgb(0 0 0 / 20%); */
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.holder {
  position: relative;
  z-index: 2;
}
.above {
  z-index: inherit;
}
.bg_holder {
  position: absolute;
  left: -1.5rem;
  top: -1.5rem;
  z-index: 1;
  background-image: url(../assets/images/bg.png);
  background-size: 32% auto;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  max-width: calc(100% + 3rem);
  width: calc(100% + 3rem);
  height: calc(100% + 1.5rem);
  padding: 0;
}
.background {
  background-image: linear-gradient(180deg, #fffffff7 0%, #ffffff 100%);
  position: absolute;
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  background-position: top center;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.card_title {
  font-family: "quicksand_medium", Sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  color: var(--green-color-dark);
  letter-spacing: 4.3px;
}
.border_bottom {
  border-bottom: 1px solid var(--gold-color);
}
.profile_img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  position: relative;
  border: 2px solid #fff;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 5px 5px 35px 0px rgb(0 0 0 / 17%);
}
.profile_img img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.item_img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
  background-color: rgba(0, 0, 0, 0.1);
}
.item {
  color: #4E5356;
  font-size: 15px;
}
.delete {
  color: #E0E0E0;
  cursor: pointer;
}
.delete:hover {
  color: var(--red-color);
}
.borders {
  border-bottom: 1px solid #E0E0E0;
}
.borders:hover {
  background-color: rgb(244, 244, 244);
}
.hide {
  visibility: hidden;
}
.background-grey {
  background-color: rgb(244, 244, 244);
}
.item_nav {
  background-color: rgba(0, 0, 0, 0.53);
}
.hover:hover {
  color: var(--gold-color);
}
.disabled {
  opacity: 0.7;
}
.selectFrequency .form-control {
    background-color: var(--green-color-dark);
    color: var(--gold-color);
    border-radius: 25px;
    border: 2px solid var(--green-color-dark);
    font-size: 1rem;
    height: calc(2.5rem + 4px);
    padding: 0;
    font-weight: 500;
    text-align: center;
  }
  .selectFrequency .form-control:focus {
    background-color: transparent;
    border-color: transparent;
    outline: 0;
    box-shadow: none;
  }
  .selectFrequency .form-control-inner {
    background-color: transparent;
    position: relative;
    color: var(--gold-color);
    border-radius: 25px;
    border: none;
    font-size: 1rem;
    height: calc(2.5rem + 4px);
    padding: 0 1rem;
    font-weight: 500;
    text-align: center;
    z-index: 2;
  }

  .selectFrequency .holder {
    position: relative;
  }
  .selectFrequency .dropdown_icon {
    position: absolute;
    color: var(--gold-color);
    top: 0.8rem;
    right: 0.7rem;
    z-index: 1;
  }
</style>
